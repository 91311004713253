import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Breadcrumb, Divider, SEO, Text, Flex } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="মধু (বরই)" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
  <Text variant="h2" textAlign="center" mdxType="Text">
    মধু (বরই)
  </Text>
  <Divider mdxType="Divider" />
  <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/finance/',
        name: 'আর্থিক ব্যাপার'
      }, {
        url: '/finance/business-of-madrassa/',
        name: 'মাদরাসাতুল ইলম ফুড এন্ড বুক শপ'
      }]} mdxType="Breadcrumb" />
  <Divider mdxType="Divider" />
      <Flex mx={[0, -2]} flexWrap="wrap" mt={3} mdxType="Flex">
  <Box width={[1, 1 / 2]} px={3} py={3} mdxType="Box">
    <img src={`/assets/images/মধু-বরই.jpg`} alt="মধু (বরই)" />
  </Box>
  <Box width={[1, 1 / 2]} px={3} py={3} mdxType="Box">
    <Text mdxType="Text">
      বেশ কয়েক বছর ধরেই মাদ্‌রাসাতুল ইলম কিছু খাদ্যপণ্যের নির্ভেজাল উৎপাদন ও
      ব্যবসার সাথে জড়িত আছে। এই প্রথম খাদ্যপণ্যগুলো অনলাইনে বিক্রির জন্য
      উন্মুক্ত করা হচ্ছে, যাতে মানুষের কাছে নির্ভেজাল খাদ্যপণ্যগুলো সহজলভ্য হয়,
      আর এর লভ্যাংশ দ্বারা উপকৃত হয় মাদ্‌রাসাতুল ইলম।
    </Text>
    <Text variant="h6" mdxType="Text">এমনই একটি পণ্য, বরই ফুলের মধু:   </Text>
    <Text mdxType="Text">সাতক্ষীরা সদরের তালা ও কলারোয়া উপজেলা থেকে সংগ্রহ করা হয় এই অতি মিষ্টি বরই ফুলের মধু।</Text>
    <Text mdxType="Text">
      মূল্য: <br />- ৫০০ গ্রাম ৩০০ টাকা 
    </Text>
    <Text mdxType="Text">সাথে ডেলিভারী চার্জ: 60~120 টাকা</Text>
  </Box>
      </Flex>
      <Text mt={3} textAlign="center" fontWeight="bold" mdxType="Text">
  Call & Whatsapp for order: 01914-723676
      </Text>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      